import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Facet from "./Facet/Facet";

import { Button } from "@mui/material";

import styles from "./Facets.module.sass";
import fields from "@/fields.json";

import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

/**
 * Results facet component
 * @memberof Components
 * @function Facets
 */

const Facets = ({ setFacet, setMissing, facets, resetFacet, searchFacets, searchCategory, resetAllFacets }) => {
	const facetFields = fields.fieldSets.filter(field => field.categoryName === searchCategory)[0];
	const otherFields = facetFields ? facetFields.otherFields : [];

	const [chosenFacet, setChosenFacet] = useState();
	const [isAdvenceFacetOpen, setIsAdvenceFacetOpen] = useState(false);

	const breakpoint = useMediaQuery("(max-width: 1199.98px)");

	const [active, setActive] = useState(true);

	const { t } = useTranslation();

	const isContrast = useSelector(state => state.app.isContrast);
	const fillColor = isContrast ? "yellow" : "#005CA9";


	const toggleDropdown = () => {
		setActive(prev => !prev);
	};

	if (otherFields.includes("time") && !facets.filter(item => item.label === "time").length) {
		facets.push({ label: "time", value: "" });
	}

	useEffect(() => {
		if (facets.length === 7) {
			const timeObj = {
				label: "time",
				value: "",
			};
			facets.push(timeObj);
		}
	}, [facets, facetFields, otherFields]);

	useEffect(() => {
		if (breakpoint) {
			setActive(false);
		} else {
			setActive(true);
		}

	}, [breakpoint]);

	return (
		// !breakpoint &&
		<div className={styles["facets"]}>
			<div className={styles["facets__title"]}>
				{!breakpoint && <h3 className={styles["facets__text--title"]}>{t("results:facets.header")}</h3>}
				<Button
					variant="text"
					className={styles["facets__title-button"]}
					onClick={resetAllFacets}
					disabled={!searchFacets || !searchFacets.length}
					sx={[
						{
							color: fillColor,
						},
						breakpoint && {
							width: 1,
							color: "#4a4a4a",
							// textAlign: 'right'
							justifyContent: "right",
						},
					]}
				>
					<span className={styles["facets__text--button"]}>{t("results:facets.clearAll")}</span>
				</Button>
			</div>
			<div className={breakpoint ? styles["facets__group-mobile"] : styles["facets__group"]}>
				{/*** Toggle Facets in mobile  ***/}

				{breakpoint && !isAdvenceFacetOpen && (
					<Button
						className={styles["facet__toggle"]}
						variant="contained"
						id="toggle"
						onClick={toggleDropdown}
						endIcon={active ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
						sx={[
							{
								bgcolor: "#4A4A4A",
								boxShadow: "none",
								textAlign: "left",
								justifyContent: "space-between",
								width: 1,
								paddingLeft: 2.5,
								paddingTop: 3,
								paddingBottom: 3,
								transition: 0.5,
								fontSize: 18,
								color: "#FFFFFF",
								"&:hover": {
									bgcolor: "#4A4A4A",
									boxShadow: "none",
								},
								"&.Mui-disabled": {
									color: "#7f7f7f",
									bgcolor: "#c0c0c0",
									borderColor: "#c0c0c0",
								},
							},
						]}
					>
						{/* {t(`results:facets.fields.${facet}`)} */}
						Filtrowanie
					</Button>
				)}

				{/* // ********************************************** */}

				{!chosenFacet && active
					? facets.map(facet => (
							<Facet
								facet={facet.label}
								value={facet.value}
								key={facet.label}
								setFacet={setFacet}
								setMissing={setMissing}
								resetFacet={resetFacet}
								searchFacets={searchFacets}
								setChosenFacet={setChosenFacet}
								isAdvenceFacetOpen={isAdvenceFacetOpen}
								setIsAdvenceFacetOpen={setIsAdvenceFacetOpen}
							/>
					  ))
					: facets
							.filter(item => item.label === chosenFacet)
							.map(facet => (
								<Facet
									facet={facet.label}
									value={facet.value}
									key={facet.label}
									setFacet={setFacet}
									setMissing={setMissing}
									resetFacet={resetFacet}
									searchFacets={searchFacets}
									isAdvenceFacetOpen={isAdvenceFacetOpen}
									setIsAdvenceFacetOpen={setIsAdvenceFacetOpen}
									setChosenFacet={setChosenFacet}
								/>
							))}
			</div>
		</div>
	);
};

export default Facets;
